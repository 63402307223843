<template>
  <div v-if="false" />
</template>

<script>
import RequestBuilder from "./engine/mixins/RequestBuilder.vue";
import Preprocessor from "./engine/mixins/Preprocessor.vue";
import Connector from "./engine/mixins/Connector.vue";
import Postprocessor from "./engine/mixins/Postprocessor.vue";

// Listens to main application events and dispatches appropriate async actions
// @group App
export default {
  name: "Engine",
  mixins: [RequestBuilder, Preprocessor, Connector, Postprocessor],
  mounted() {
    this.eventHub.$on("getPlans", (customPayload = null) => {
      this.go(customPayload);
    });
    this.eventHub.$on("fetchNext", (data) => {
      return this.fetchSegment(data, "next");
    });
    this.eventHub.$on("fetchPrevious", (data) => {
      return this.fetchSegment(data, "previous");
    });
    this.eventHub.$on("updatePlan", (plan) => {
      this.updatePlan(plan).then((response) => {
        const arg = {
          data: {
            plannedRoutes: [response.data.plannedRoute],
          },
        };

        arg.uid = "plans-download.post";
        const normalized = this.postprocessResponse(arg);
        // @vuese
        // Signals that plan has been updated with using the Update button in map
        // @arg `Object`
        this.eventHub.$emit("planUpdated", {
          planId: plan.id,
          plans: normalized,
        });
      });
    });
    this.eventHub.$on("executePlan", (plan) => {
      this.executePlan(plan).then((response) => {
        // @vuese
        // Executes callback signaling that plan has been executed and we can show the result modal window
        // @arg `Object` JSON response
        this.eventHub.$emit("planExecuted", response);
      });
    });
  },
  methods: {
    // @vuese
    // Performs plans search action, including (pre/post)processing and (re)painting
    // @arg `void`
    go(customPayload = null) {
      let payload = this.buildPayload();

      if (null !== customPayload) {
        payload = customPayload;
      }

      // @vuese
      // Signals the app to clear all current plans from the panel and map
      // @arg `void`
      this.eventHub.$emit("clearPlans");
      // @vuese
      // Signals the app to erase already painted plans from the UI
      // @arg `void`
      this.eventHub.$emit("erasePlans");

      // @vuese
      // Triggers spinner and makes submit button undesponsive for the time
      // @arg `void`
      this.eventHub.$emit("loading");
      this.downloadPlans(payload)
        .then((response) => {
          response = this.postprocessResponse(response, this);
          if (
            response &&
            [
              "INVALID_REQUEST",
              "SERVER_ERROR",
              "PLAN_NOT_FOUND",
              "PLAN_NOT_FOUND_REQUEST_CHANGED",
            ].indexOf(response.data.routingStatus) !== -1
          ) {
            this.eventHub.$emit("planNotFound", {
              reason: response.data.routingStatus,
            });

            // Try to configure the left panel with the custom payload
            if (null !== customPayload) {
                this.eventHub.$emit("configurePanel", {routingRequest: customPayload});
            } // end if
            
            throw new Error(`Routing status: ${response.data.routingStatus}`);
          } // en dif

          if (response) {
            this.eventHub.$emit("logMessage", {
              type: "info",
              message: "Plans loaded",
            });
          } else {
            throw new Error("Response was empty");
          } // end if

          if (null !== customPayload) {
            this.eventHub.$emit("configurePanel", response.data);
          } // end if

          response.uid = "plans-download.post";
          response = this.postprocessResponse(response, this);
          const plans = response.data.plans;

          this.$store.commit("lastResponse", response.data);
          this.$store.commit("plans", plans);
          this.$store.commit("activePlan", plans[0].id);

          if (
            response.data.routingRequestViolations &&
            response.data.routingRequestViolations.length
          ) {
            this.eventHub.$emit("planFoundWithErrors", {
              errorsCnt: response.data.routingRequestViolations.length,
            });
          }

          // @vuese
          // Instructs the app to paint newly received plans
          // @arg `Object` JSON with fetched plans
          this.eventHub.$emit("paintPlans", plans);

          /*
            Set LatLon for Origin/Destination pins if stopIds mode was set
          */

          this.$store.commit("autoPlan", false);

          if (
            !response.data.routingRequest.origin.geoLocation &&
            response.data.plannedRoutes.length
          ) {
            const originOpts = {};
            originOpts.ll = {
              lat: response.data.plannedRoutes[0].start.geoLocation.lat,
              lng: response.data.plannedRoutes[0].start.geoLocation.lon,
            };
            originOpts.stopIds = response.data.routingRequest.origin.stopIds ? response.data.routingRequest.origin.stopIds : null; 
            this.eventHub.$emit("setOrigin", originOpts);
          } // end if
          
          if (
            !response.data.routingRequest.destination.geoLocation &&
            response.data.plannedRoutes.length
          ) {
            const destinationOpts = {};
            destinationOpts.ll = {
                lat: response.data.plannedRoutes[0].end.geoLocation.lat,
                lng: response.data.plannedRoutes[0].end.geoLocation.lon,
            };
            destinationOpts.stopIds = response.data.routingRequest.destination.stopIds ? response.data.routingRequest.destination.stopIds : null; 
            this.eventHub.$emit("setDestination", destinationOpts);
          } // end if

          setTimeout(() => {
            this.$store.commit("autoPlan", true);
          }, 1500);

          this.$store.dispatch("serialize", this.appConfig.version);
        })
        .catch((err) => {
          this.eventHub.$emit("logMessage", {
            type: "error",
            message: "Failed to download plans",
          });
          throw err;
        })
        .finally(() => {
          // @vuese
          // Triggers all post-plan-paint actions
          // @arg `void`
          this.eventHub.$emit("loadingDone");
        });
    },
    // @vuese
    // Loads new PT segment from plan detail
    // @arg `Object` {data}
    // @arg `Enum` {direction}
    fetchSegment(data, direction) {
      let origin;
      let destination;

      origin = data.current.start.geoLocation;
      origin.stop = data.current.rideDetails.ptDetails.stopTimes[0].stop;
      origin.dateTime = data.current.start.dateTime;

      destination = data.current.end.geoLocation;
      let len = data.current.rideDetails.ptDetails.stopTimes.length;
      destination.stop =
        data.current.rideDetails.ptDetails.stopTimes[len - 1].stop;

      const payload = this.buildPayload();

      const params = {
        dateTime: origin.dateTime,
        maxTripsCount: 10,
        startStopName:
          data.current.rideDetails.ptDetails.stopTimes[
            data.current.rideDetails.ptDetails.getOnStopIndex
          ].stop.gtfsStopName,
        startStopId:
          data.current.rideDetails.ptDetails.stopTimes[
            data.current.rideDetails.ptDetails.getOnStopIndex
          ].stop.gtfsStopId,
        endStopName:
          data.current.rideDetails.ptDetails.stopTimes[
            data.current.rideDetails.ptDetails.getOffStopIndex
          ].stop.gtfsStopName,
        endStopId:
          data.current.rideDetails.ptDetails.stopTimes[
            data.current.rideDetails.ptDetails.getOffStopIndex
          ].stop.gtfsStopId,
        ptSettings: payload.modesSettings.ptSettings,
        walkSettings: payload.modesSettings.walkSettings,
      };

      this.downloadSegment(origin, destination, direction, params).then(
        (res) => {
          // @vuese
          // Replaces plan detail segment with previous/next one
          // @arg `Object` New segment
          this.eventHub.$emit("switchSegments", {
            segment: data.current,
            direction: direction,
            result: res,
          });
        }
      );
    },
  },
};
</script>